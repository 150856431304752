import React, { useRef, useCallback, useEffect, useReducer, useState, Suspense } from "react"
import { navigate } from "gatsby"
import { pure } from 'recompose'
import isEqual from 'react-fast-compare'
import { reducerDeepCompare } from 'components/utils/useStateDeepEqual'
import { useDebouncedCallback } from 'use-debounce'
import dayjs from 'dayjs'

import {
  dataStore,
  filterStore,
  locationStore,
  pageStore,
  userStore
} from "state/store-zustand";
import useSEOSearch from 'components/utils/useSEOSearch';

import axios from 'axios'
import useActionsAPI from 'components/utils/actionsAPI'

import { decodeHtmlEntities, imageURlsToObjects } from 'components/utils/textUtils'
import { sendEmail } from 'components/utils/sendEmail'

import DatePicker from 'components/forms/datePicker'
import RecurringDates from 'components/forms/recurringDates'
import TagPicker from 'components/forms/tagPicker'
import VibePoints from 'components/elements/vibePoints'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

const Map = React.lazy(() => import('components/map'))
const UploadPreview = React.lazy(() => import('components/forms/addFile'))

import Autocomplete from '@mui/material/Autocomplete'
import BasicAutoComplete from 'components/search/AutoComplete/Basic'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import { FormGroup, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField';
import { useReward } from 'react-rewards'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import MuiTextField from '@mui/material/TextField';

import VibemapSearchProvider from 'components/utils/search-context/Provider'
import VibemapSearchField from 'components/search/Field'
import VibemapSearchParameterPersister from 'components/search/ParameterPersister'

import './form.scss'

const AddEventForm = ({
  onSubmitCallback = null,
  showAddress = true,
  showButtons = true,
  showSearch = true,
  title = null,
  updateOnly = false,
  inDialog = false,
  onCancel = null,
  submitPressed = false,
  maxWidth = '1536px',
  ...props
}) => {

  // Global state
  const embedded = pageStore((state) => state.embedded)
  const isMobile = pageStore((state) => state.isMobile)
  const message = pageStore((state) => state.message)
  const setAndShowMessage = pageStore((state) => state.setAndShowMessage)
  const pageCurrent = pageStore((state) => state.pageCurrent)
  const themeClass = pageStore((state) => state.themeClass)
  const themeName = pageStore((state) => state.themeName)
  const themeObject = pageStore((state) => state.themeObject)

  const eventCurrent = dataStore((state) => state.eventCurrent)
  const eventNew = dataStore((state) => state.eventNew)
  const currentLocation = locationStore((state) => state.currentLocation)
  const tagsCurrent = filterStore((state) => state.tagsCurrent)
  const setCurrentEvent = dataStore((state) => state.setEventCurrent)
  const setEventNew = dataStore((state) => state.setEventNew)

  const isLoggedIn = userStore

  useEffect(() => {
    // Update form tags
    if (tagsCurrent) {
      setFormData({
        ...formData,
        tags: tagsCurrent
      })
    }
  }, [tagsCurrent])

  // Page State
  const [addNew, setAddNew] = useState(false)
  const [buttonText, setButtonText] = useState('Add New Event')
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const formRef = React.createRef(null)
  const isBrowser = typeof window !== `undefined`

  // Date and time
  const today_start = dayjs().hour('18').minute('00').second('00')
  const today_end = dayjs().hour('21').minute('00').second('00')
  const [startDate, setStartDate] = useState(today_start)
  const [startTime, setStartTime] = useState(today_start)
  const [endDate, setEndDate] = useState(today_end)
  const [endTime, setEndTime] = useState(today_end)
  const hasEditedDate = useRef(false)
  const isEditingEndDate = useRef(false)


  // If startDate changes and is greater than endDate, set endDate to startDate
  useEffect(() => {
    if (startDate.isAfter(endDate) && !isEditingEndDate.current) {
      setEndDate(startDate)
      hasEditedDate.current = true
    }
  }, [startDate])

  // If time changes, update date
  useEffect(() => {
    const newStartDateTime = dayjs(startDate).hour(startTime.hour()).minute(startTime.minute()).second(startTime.second())
    const newEndDateTime = dayjs(endDate).hour(endTime.hour()).minute(endTime.minute()).second(endTime.second())

    setStartDate(newStartDateTime)
    setEndDate(newEndDateTime)

    setTimeout(() => { isEditingEndDate.current = false }, 300)
  }, [startTime])

  // If endTime changes, update endDate
  useEffect(() => {
    const newEndDateTime = dayjs(endDate).hour(endTime.hour()).minute(endTime.minute()).second(endTime.second());
    setEndDate(newEndDateTime)
    setTimeout(() => { isEditingEndDate.current = false }, 300)
  }, [endTime]);


  const [venue, setVenue] = useState(null)
  const [organizer, setOrganizer] = useState(null)
  const [place, setPlace] = useReducer(reducerDeepCompare, null)
  const [clearForm, setClearForm] = useState(false)

  const [vibesCombined, setVibes] = useReducer(reducerDeepCompare, [])
  const [vibeScore, setVibeScore] = useState(1)

  const [missing, setMissing] = React.useState([])

  const { reward: balloonsReward, isAnimating: isBalloonsAnimating } = useReward(
    'balloonsReward',
    'balloons',
    {
      elementCount: 20,
      position: 'fixed',
      colors: ['#F72585', '#7209B7', '#3A0CA3', '#4361EE', '#4CC9F0'],
      zIndex: 10,
    }
  )

  useEffect(() => {
    if (props.message && props.message !== message) {
      setAndShowMessage(props.message)
    }
  }, [props.message])

  const [mapHeight, setMapHeight] = useState(240)
  const [mapWidth, setMapWidth] = useState(380)
  const [zoom, setZoom] = useState(14)
  useEffect(() => {
    // Check form width
    if (isBrowser && formRef.current) {
      setMapWidth(formRef.current.offsetWidth)
    }
  }, [formRef])

  useEffect(() => {
    // Set options based on theme
    if (themeClass === 'theme-peoria') {
      setAddNew(true)
    }

  }, [themeClass])

  const latitude = place && place.geometry
    ? place.geometry.coordinates[1]
    : currentLocation
      ? currentLocation.latitude
      : 32

  const longitude = place && place.geometry
    ? place.geometry.coordinates[0]
    : currentLocation
      ? currentLocation.longitude
      : -122

  const [foundEvent, setFoundEvent] = useState(false)

  const [images, setImages] = useReducer(reducerDeepCompare, [])

  // Use theme rules
  const is_approved = themeClass != "theme-peoria"
  const showPoints = themeObject?.eventForm?.showVibePoints

  const [formData, setFormData] = useState({
    name: null,
    description: null,
    start_date: startDate.format('YYYY-MM-DDTHH:mm:ss'),
    end_date: endDate.format('YYYY-MM-DDTHH:mm:ss'),
    images: images,
    is_approved: is_approved,
    is_free: false,
    is_online: false,
    is_recurring: false,
    link: null,
    hotspots_place: place,
    price_display: null,
    recurs: false,
    recurrence: null,
    subcategories: [],
    tags: [],
    vibes: [],
  })

  const {
    name, description, address, link,
    price, is_free, is_online,
    subcategories, vibes
  } = formData

  // If event is already set, popluate the form
  const [defaultRecurrence, setDefaultRecurrence] = useState(null)
  useEffect(() => {
    //console.log('DEBUG setform from event...', eventCurrent);
    if (eventCurrent) {
      const eventProperties = eventCurrent?.properties
      const eventPlace = eventProperties?.hotspots_place

      const address = eventPlace?.properties?.address
      const recurrence = eventProperties?.recurrence
      const recurs = recurrence && recurrence?.length > 0
      if (recurs && !defaultRecurrence) {
        setDefaultRecurrence(recurrence)
      }

      // If place is set, set the place
      if (eventPlace) {
        setPlace(eventPlace?.properties)
        const venueName = eventPlace?.properties?.name
        setVenue(venueName)
      }

      const startDateFound = eventProperties?.start_date
      const endDateFound = eventProperties?.end_date

      setStartDate(dayjs(startDateFound))
      setEndDate(dayjs(endDateFound))

      const placeFound = eventProperties?.hotspots_place
      if (placeFound) setPlace(placeFound)

      const newFormData = {
        ...formData,
        name: eventProperties?.name,
        description: eventProperties?.description,
        address: address,
        start_date: eventProperties?.start_date,
        end_date: eventProperties?.end_date,
        images: eventProperties?.images,
        is_approved: eventProperties?.is_approved,
        is_free: eventProperties?.is_free,
        is_online: eventProperties?.is_online,
        is_recurring: eventProperties?.is_recurring,
        link: eventProperties?.url,
        hotspots_place: eventProperties?.hotspots_place,
        price_display: eventProperties?.price,
        recurs: recurs,
        recurrence: eventProperties?.recurrence,
        subcategories: eventProperties?.subcategories,
        tags: eventProperties?.tags,
        vibes: eventProperties?.vibes,
      }
      setFormData(newFormData)

      setImages(eventProperties?.vibemap_images)

      // Validate if there's a name, place
      if (newFormData?.name && newFormData?.hotspots_place) {
        handleValidation(null, newFormData)
      }

    }
  }, [eventCurrent])


  const handleFormUpdate = (e, value, name) => {
    console.log('handleFormUpdate ', value, name)
    // TOOD: clone object so that it triggers a rerender
    const newFormData = {
      ...formData,
      [name]: value
    }
    setFormData(Object.assign({}, newFormData))

    // Update event current preview
    const eventUpdated = {
      ...eventCurrent,
      properties: {
        ...eventCurrent?.properties,
        [name]: value
      }
    }

    //setEventNew(eventUpdated)
    //setEventCurrent(eventUpdated)
    console.log('handleFormUpdate newFormData', newFormData, eventUpdated);

  }


  const handleValidation = async (e, data) => {
    //console.log('handleValidation ', e);

    const formToCheck = data || formData
    // Check if form is valid
    // No name / title
    if (formToCheck?.name == null || formToCheck?.name == '') {
      setAndShowMessage('Please enter a name for the event')
      sendEmail('No name entered for event', formToCheck)
      setMissing([...missing, 'name'])
      return false
    } else {
      setMissing(missing.filter(item => item !== 'name'))
    }

    // If no place
    const noPlace = !place && formToCheck?.address == null || formToCheck?.address == ''
    if (noPlace) {
      sendEmail('No place entered for event', formToCheck)
      setMissing([...missing, 'location'])
      setAndShowMessage('Please select a place or enter an address')
      return false
    } else {
      setMissing(missing.filter(item => item !== 'location'))
    }

    return true
  }


  // API Actions for Form
  const { patchEvent } = useActionsAPI()

  const onSubmit = useCallback(async (e, data) => {
    e.preventDefault();
    console.log("DEBUG Form submitted: ", data)

    // Cloud Dev: 143.198.100.9
    // Local Dev: 127.0.0.1:8001
    const is_dev = false
    const local_dev = 'http://localhost:9000/v0.3' //http://127.0.0.1:9000/v0.3
    const prod = 'https://api.vibemap.com/v0.3'

    const domain = is_dev ? local_dev : prod
    //const add_place_endpoint = "https://api.vibemap.com/v0.3/places/"
    //const add_event_endpoint = "https://dev.vibemap.com/add_event"
    const add_event_endpoint = `${domain}/events/?return_value=object`

    //console.log('Submit Place data: ', place);
    const point = place?.geometry?.coordinates

    const is_valid = await handleValidation(e, data)
    if (!is_valid) return false

    const place_data = {
      //...place, // FIXEME: Include all fields?
      "name": place?.name
        ? place?.name
        : place?.properties?.name,
      "address": address
        ? address
        : place?.properties?.address,
      "categories": [],
      "description": "",
      "subcategories": data?.select,
      "vibes": vibesCombined,
      "images": images,
      "telephone": data?.phone,
      "point": point,
      //"url": data.website,
      // Include blank
      "neighborhood": "",
      "short_description": "",
      "timezone": "",
      "hours": "",
      "data_sources": [],
      "is_closed": false,
      "reviews": [],
      "offers": [],
      "other_vibes": [],
      "opening_hours": [],
      "price": null,
      "ratings": [],
      "stats": [],
      "tags": [],
      "tips": [],
      "attributes": [],
      "communities": [],
      "editorial_categories": [],
      "hotspots_events": [],
      "aggregate_rating": 4,
      "foursquare_id": "",
      "code": ""
    }

    const is_recurring = formData.recurs == true ? true : false
    const start_date = startDate.format('YYYY-MM-DDTHH:mm:ss')
    const end_date = endDate.format('YYYY-MM-DDTHH:mm:ss')

    const event_data = {
      ...formData,
      "start_date": start_date,
      "end_date": end_date,
      "images": images,
      "price_display": price,
      "url": link,
      "hotspots_place": place_data,
      "subcategories": [],
      "vibes": vibesCombined,
    }

    setAndShowMessage('Adding event and location. This might take a moment...')

    // TODO: if updateOnly is set, use a PATCH
    let response = null
    if (updateOnly == true) {
      response = await patchEvent(eventCurrent.id, event_data, themeName)
      const is_success = response && (response.status == 200 || response.status == 201) && response.data
      const has_errror = response && response.status == 400 || response.data.errors
      if (is_success && !has_errror) {
        setAndShowMessage('Event was updated!', true)
        balloonsReward()
        onSubmitCallback && onSubmitCallback(data)
      }
      else {
        const message = response.data?.message || 'There was a problem updating this event. Please try again.'
        setAndShowMessage('There was a problem updating this event. Please try again.')
        sendEmail(message, event_data)
      }
      return true
    }

    if (updateOnly == false) {
      response = await axios({
        method: 'post',
        url: `${add_event_endpoint}${themeName ? `?theme=${themeName}` : ''}`,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(event_data)
      }).catch(e => {
        console.log('Problem with request ', e);
        const message = 'There was a problem with this place. Please try again.'
        setAndShowMessage(message)
        sendEmail(message, formData)
      })
    }

    const is_success = response && (response.status == 200 || response.status == 201) && response.data
    const has_errror = response && response.status == 400 || response.data.errors

    if (is_success && !has_errror) {

      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' })

      // TODO: from the respone, show a preview and link
      //setAndShowMessage(response.data)
      const data = response?.data
      const id_new = data?.id
      const event_name = data?.properties?.name
      const link_new = `https://vibemap.com/events/${id_new}`
      const link = `<a href="${link_new}" target="_blank">${event_name}</a>`
      const message = `Thanks for adding ${link}! We'll review your event in the next 48 hours.`
      setAndShowMessage(message, true)

      balloonsReward()
      setButtonText('Add Another')
      setAddNew(false)
      setCurrentEvent(null)
      setPlace(null)
      setFormData(Object.assign({}, {}))
      setImages([])

      onSubmitCallback && onSubmitCallback(data)
      return true
    } else {
      const errorMessages = Object.entries(response.data.errors)
        .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
        .join('\n')
      const message = response.data?.errors
        ? 'Sorry, there was a problem ' + errorMessages
        : 'Sorry, there was a problem. Please try again or email the info to <a mailto="events@vibemap.com">events@vibemap.com</a>.'
      setAndShowMessage(message)
      sendEmail(message, formData)
      return false
    }
  }, [formData, place, startDate, endDate, images, vibesCombined, vibes, tagsCurrent, currentLocation])


  // Fetch event info by URL
  useEffect(() => {

    (async () => {
      const is_editing = pageCurrent.includes('edit-event') || updateOnly
      const has_title_or_desc = formData?.name || formData?.description

      if (link && !is_editing && !has_title_or_desc) {
        // set the link in the formData
        const newFormData = { ...formData, link: link }
        setFormData(newFormData)

        const url = link.replace(/\/+$/, '')
        console.log(`TODO: Fetch more info based on website `, url);
        const endpoint = `https://dev.vibemap.com/lookup_event?url=${url}`
        setIsLoading(true)

        setAndShowMessage('Searching for event and vibes...')
        const response = await axios.get(endpoint).catch(error => console.log(`Problem with website request`, error))
        const event_info = response && response.data
        setIsLoading(false)


        if (event_info) {
          setAndShowMessage('Found event! Check the info and submit to our calendar')
          setFoundEvent(true)
        } else {
          setAndShowMessage('Could not automaticallly find info for this event')
        }
        console.log(`SUCCESS: event response`, endpoint, event_info)

        // Update defaults/placeholders
        if (event_info) {

          const address = event_info?.location?.address
          const nameBest = event_info?.name || event_info?.title
          const nameClean = nameBest ? decodeHtmlEntities(nameBest) : null
          const venueName = event_info?.location?.name ? decodeHtmlEntities(event_info?.location?.name) : null
          const organizer = event_info?.organizer
          if (venueName) setVenue(venueName)
          const organizerName = organizer?.name || organizer?.title || organizer
          const organizerEmail = organizer?.email
          const organizerClean = organizerName ? decodeHtmlEntities(organizerName) : null
          if (venueName == null) setVenue(organizer)

          const venueAddress = event_info?.location?.address?.streetAddress || event_info?.location?.address?.name || address
          const venueAddressText = venueAddress ? decodeHtmlEntities(venueAddress) : null

          setFormData({
            ...formData,
            name: nameClean || formData?.name,
            address: venueAddressText,
            description: event_info?.description,
            organizer: organizerClean,
            organizer_email: organizerEmail,
          })

          // Remove timezone offset; otherwise browser parses to browser's local time
          const offset_regex = /Z|([+-]\d{2}:\d{2})$|([+-]\d{4})$/;

          const end_date = event_info?.end_date || event_info?.endDate
          if (end_date) {
            const endDateLocal = end_date?.replace(offset_regex, '')
            const endDateTime = dayjs(endDateLocal)
            setEndDate(endDateTime)
          }

          const start_date = event_info?.start_date || event_info?.startDate
          if (start_date) {
            const startDateLocal = start_date?.replace(offset_regex, '')
            const startDateTime = dayjs(startDateLocal)
            setStartDate(startDateTime)
          }

          console.log('DEBUG: found start and end date ', start_date, end_date);

          // TODO: use imageURlsToObjects util
          if (event_info?.image || event_info?.images) {
            const images_found = event_info?.images || event_info?.image

            // If there's a best image i.e. event_info.image, use that
            // Otherwise show all images

            const images_unique = Array.isArray(images_found)
              ? [...new Set(images_found)]
              : [images_found]

            // If image is array map to images, else add to images
            const images = Array.isArray(images_unique)
              ? images_unique.map(image => {
                return {
                  src: image,
                  url: image
                }
              })
              : [{
                src: event_info.image,
                url: event_info.image
              }]

            const vibemapImages = images.map(image => {
              try {
                // TODO: Make a util to handle a variety of image types
                const srcURL = typeof (image.src) === 'string'
                  ? image.src.split('/').pop()
                  : Object.values(image.src)[0]

                return {
                  ...image,
                  filename: srcURL,
                  url: image.src
                }
              } catch (error) {
                console.log('Error with image', image, error)
              }
            })

            console.log('DEBUG handle images ', images);
            setImages(vibemapImages)
          }
        }
      }
    })()
  }, [link])

  // If images are set, update the formData
  useEffect(() => {
    if (images && images.length > 0) {
      const newFormData = {
        ...formData,
        images: images
      }
      setFormData(newFormData)
      console.log('DEBUG: set formData images ', newFormData);
    }
  }, [images])


  useEffect(() => {
    if (submitPressed) {
      const e = new Event('submit', { cancelable: true })
      onSubmit(e, formData)
      console.log('DEBUG: submitPressed ', submitPressed);
    }
  }, [submitPressed])

  // Check if values have changed
  // Wrapped with FormSpy to get the values
  const Buttons = showButtons
    ? (
      <div className={'addEventForm'} onSubmit={(e) => onSubmit(e, formData)}>
          {() => (
            <form key='add-form' id='add-form'>
              <div className='buttonGroup' style={{ marginTop: 8 }}>
                {showPoints
                  ?  <VibePoints vibeScore={vibeScore} />
                  : null
                }

                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button disabled={submitting || !valid} style={{ marginRight: 8 }} type="submit" color="primary" variant="contained">
                  Submit
                </Button>
              </div>
            </form>
          )}
      </div>
    )
    : null

  const FileUploadComponent = (props) => {
    //const { input } = useFieldApi(props)

    const handleFiles = (imageFiles) => {
      //console.log(`handleFiles `, imageFiles)
      //input.onChange(imageFiles)
      setImages(imageFiles)
    }

    const handleUploadedFiles = (uploadedFiles) => {
      console.log(`handleUploadedFiles `, uploadedFiles)
      setImages(uploadedFiles)
      const newFormData = {
        ...formData,
        images: uploadedFiles
      }
      setFormData(newFormData)
    }

    return (
      <Suspense fallback={<span>...</span>}>
        <UploadPreview
          images={images}
          onError={handleError}
          onUpload={handleUploadedFiles}
          onChange={handleFiles} />
      </Suspense>
    )
  }

  const [hasError, setHasError] = useState(false)

  const datePickerRef = useRef(null)
  const lastActiveInput = useRef(null)

  useEffect(() => {
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.addEventListener("focus", (event) => {
        lastActiveInput.current = document.activeElement
      });
    }
  }, []);

  const handleDateChange = (newValue, setDate) => {
    lastActiveInput.current = document.activeElement.value
    //setStartDate(newValue)
    setDate(newValue)
    //callback(newValue, label)
  }

  const handleTimeChange = (newValue, label) => {
    console.log('handleTimeChange ', newValue, label);
    lastActiveInput.current = document.activeElement.value
    //callback(newValue, label, true)
  }

  const EventPicker = (props) => {
    const results = [
      { title: 'The Shawshank Redemption', year: 1994 },
      { title: 'The Godfather', year: 1972 },
    ]

    const handleEventPicker = (newInputValue) => {
      console.log('handlePlacePicker ', newInputValue);
      debugger
    }

    return (
      <div>
        Place search goes here.
        <BasicAutoComplete
          disablePortal
          id="combo-box-demo"
          loading={isLoading}
          open={true}
          onInputChange={(event, newInputValue) => {
            handleEventPicker(newInputValue);
          }}
          options={results.map(option => option.title)}
          sx={{ width: 300 }}
          threshold={0.1}
          renderInput={(params) => <MuiTextField {...params} label="Movie" />}
        />
      </div>
    )
  }

  const handlePlaceClick = (event, data) => {
    //console.log('handleClick on Place ', data?.details)
    if (data.details) {
      const newPlace = data.details
      setPlace(newPlace)
      setVenue(data?.label)

      // TODO: set address from place
      //setAddress(data?.subLabel)
      setFormData({
        ...formData,
        address: data?.subLabel,
        place: newPlace
      })
    }
  }

  const handlePlaceUpdate = (data) => {
    const places = data?.places

    if (places && places.length > 0) {
      const newPlace = data.places[0]?.details

      if (!isEqual(place, newPlace)) {
        // If place was already found, set the place
        //console.log('DEBUG should set place ', place, newPlace);
        if (foundEvent && place == null) {
          setPlace(newPlace)
        }
      }
      // SEt focus after timeout
      setTimeout(() => {
        const input = document.querySelector('#place-search')
        input?.focus()
        console.log('DEBUG: set focus on place search');
      }, 200)
    }
  }

  const mapRef = useRef()
  const MapMarker = (props) => {
    const { input, meta, label } = useFieldApi(props)
    //input.onChange(input.value)
    //console.log('MapMarker ', input, meta, label)

    const handleMarker = (event) => {
      input.value = event.lngLat.toString()
      input.onChange(event.lngLat.toString())
    }

    return (
      <div className={`MuiGrid-root MuiGrid-item`}>
        <Suspense fallback={<div>Loading...</div>}>
          <Map
            mapRef={mapRef}
            latitude={latitude}
            longitude={longitude}
            onChange={handleMarker}
            showExplore={false}
            showPlaces={false}
            showDraggableMarker={true}
            map3d={false}
            height={mapHeight}
            width={mapWidth}
            zoom={zoom} />
        </Suspense>
      </div>
    )
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    else {
      navigate(-1)
    }
  }

  const handleError = (hasError = true, message) => {
    console.log(`handleError`, hasError, message)
    setHasError(hasError)
    setErrorMessage(message)
  }

  const formStyle = {
    width: isMobile || inDialog ? '100%' : '80%',
    margin: '0 auto',
    position: 'relative'
  }

  const fieldGroupStyle = {
    padding: isMobile ? 0 : '2rem',
    marginTop: `2rem`,
    width: `100%`
  }

  const buttonGroupStyle = {
    marginTop: 8,
    position: inDialog ? 'sticky' : 'fixed',
    left: 0,
    right: 0,
    width: '100%',
    margin: '0 auto',
    maxWidth: inDialog ? maxWidth : '100%', // TODO: Set as shared var
  }

  const gridStyle = {
    marginBottom: '1.4rem'
  }

  const descriptionStyle = {
    ...gridStyle,
    '& .MuiInputBase-input': { padding: '14px' }
  }


  const FormInputText = ({
    name,
    label,
    value,
    placeholder,
    required = false,
    error = false,
  }) => {
    //const value = formData[name]
    return (
      <Grid sx={gridStyle}>
        <TextField
          //helperText={fieldState?.error ? fieldState?.error?.message : null}
          size="small"
          //error={!!fieldState?.error}
          onChange={(e, value) => {
            handleFormUpdate(e, e?.target?.value, name)
          }}
          value={value}
          fullWidth
          label={label}
          InputLabelProps={{ shrink: value !== '' && value !== null }}
          required={required}
          error={error}
          variant="outlined"
        />
      </Grid>
    )
  }

  const CheckboxInput = ({ name, label }) => {
    const value = formData[name]

    return (
      <Grid sx={gridStyle}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              size="large"
              onChange={(e, val) => handleFormUpdate(e, e?.target?.checked, name)}
              />
          }
          label={label}
          size='medium'
        />
      </Grid>
    );
  }

  const TimePicker = ({
    value = startDate,
    label = "Time",
    setValue = () => { },
    ...props
  }) => {
    return (
      isMobile
        ? <MobileTimePicker
            label={label}
            value={value}
            onChange={(newValue) => setValue(newValue)}
            renderInput={(params) => <TextField {...params} sx={{ maxWidth : '80px'}} />}
        />
        : <DesktopTimePicker
            label={label}
            value={value}
            onChange={(newValue) => {
              // if editing end date, set flag
              if (label == 'End Time' || label == 'End Date') isEditingEndDate.current = true
              setValue(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
        />
    )
  }


  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  const { searchEvents, searchGoogle, searchResultsToOptions } = useSEOSearch();

  // TODO: Pass the city from Theme
  const handleSearch = useDebouncedCallback(async (value) => {
    setIsLoading(true);
    const searchGooglePromise = searchGoogle('events ' + value);
    const searchEventsPromise = searchEvents(value);

    Promise.race([searchGooglePromise]).then(results => {
      // Update the UI with the first result
      const options_new = searchResultsToOptions(results);
      setOptions(options_new);
    });

    Promise.all([searchGooglePromise, searchEventsPromise]).then(([resultsSearch, resultsEvents]) => {
      const options_events = resultsEvents && searchResultsToOptions(resultsEvents) || []
      const options_search = resultsSearch && searchResultsToOptions(resultsSearch) || []
      // Update the UI with the final results
      const options_new = options_events.concat(options_search)
      setOptions(options_new);
      setIsLoading(false);
      debugger
    });

  }, 500);


  const debounceTime = useDebouncedCallback((func, value = null) => {
    console.log('DEBUG updateTime ', value, name);
    func(value)
  }, 300)



  const handleDescriptionPasted = useCallback(async (e) => {
    console.log('DEBUG handleDescriptionPasted');
    const pastedText = e.clipboardData.getData('text');
    const is_editing = pageCurrent.includes('edit-event');

    if (formData.description || is_editing) {
      // Skip the API request if there's already a description
      return;
    }

    const endpoint = `https://dev.vibemap.com/lookup_from_description?text=${pastedText}`;

    setAndShowMessage('Check the description for event details...');
    const response = await axios.get(endpoint).catch(error => console.log(`Problem with website request`, error));
    const event_info = response && response.data;

    if (event_info) {
      setAndShowMessage('Getting details from pasted text');

      const nameBest = event_info?.name || event_info?.title;
      const nameClean = nameBest ? decodeHtmlEntities(nameBest) : null;
      const venueName = event_info?.location?.name ? decodeHtmlEntities(event_info?.location?.name) : null;
      const organizerName = event_info?.organizer;
      const start_date = event_info?.start_date;
      const address = event_info?.address;

      setFormData((prevFormData) => {
        let newFormData = { ...prevFormData };

        if (nameBest && !prevFormData.name) {
          newFormData.name = nameClean;
        }

        if (address && !prevFormData.address) {
          newFormData.address = address;
        }

        if (venueName && !venue) {
          setVenue(venueName);
        }

        if (organizerName && !organizer) {
          setOrganizer(organizerName);
          if (!venueName) setVenue(organizerName);
          newFormData.organizer = organizerName;
        }

        if (start_date && !hasEditedDate.current) {
          const startDateTime = dayjs(start_date);
          if (startDateTime.isValid()) {
            setStartDate(startDateTime);
            newFormData.start_date = startDateTime.format('YYYY-MM-DDTHH:mm:ss');

            if (!endDate) {
              const endDateTime = startDateTime.add(2, 'hours');
              setEndDate(endDateTime);
              newFormData.end_date = endDateTime.format('YYYY-MM-DDTHH:mm:ss');
            }
          } else {
            console.error('WARN: Invalid start date:', start_date);
          }
        }

        return newFormData;
      });

      const vibemapImages = imageURlsToObjects(event_info);
      setImages(vibemapImages);
    }
  }, [formData, pageCurrent, venue, organizer, endDate]);


  const handleSelect = (event, option) => {
    //console.log('handleSelect ', option);
    setFormData({
      ...formData,
      name: option?.title || option?.name,
      link: option?.url,
    })

    setOpen(false)
  }

  const handleRRuleChange = (rule, hasChange) => {
    console.log('DEBUG handleRRuleChange ', rule, hasChange);

    if (hasChange) {
      setFormData({
        ...formData,
        recurrence: rule
      })
    }
  }

  const handleTags = (tags) => {
    console.log('DEBUG handleTags ', tags);
    setFormData({
      ...formData,
      tags: tags
    })
  }

  const SearchBar = ({ title, message, ...props }) => {

    return (
      <>
        <h3>{title}</h3>

        <Paper sx={{
          p: `1rem`,
          fontSize: `1.4rem`,
          background: `linear-gradient(45deg, #FFEFE7 10%, #FFFFFF, #EAE0EF 90%)`,
        }}>
          {message
            ? <p>{message}</p>
            : <>
                <p className='small' style={{ marginBottom: '1.4rem' }}>
                  Is your event already online?
                </p>
                <Autocomplete
                  id="free-solo-demo"
            autoHighlight
            freeSolo
            onInputChange={(event, newInputValue) => { handleSearch(newInputValue) }}
            onChange={(event, value) => { handleSelect(event, value) }}
            onOpen={() => { setOpen(true) }}
            onBlur={() => { setOpen(false) }}
            loading={isLoading}
            open={open}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.title}
            options={options}
            renderOption={(props, option) => (
              <li {...props}>
                {option.title}
                {option.link ? <><br /><span className='small'>{option.link}</span></> : null}
              </li>
            )}
            renderInput={(params) => (
              <>
              <TextField
                {...params}
                placeholder="Enter the name or link..."
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                size="small"
                style={{ borderRadius: 10, }}
              />
              <p className='small'>🪄 Event search is an new feature. Send feedback to <a mailto="events@vibemap.com">events@vibemap.com</a></p>
              </>
                  )} />
              </>
          }

        </Paper>
      </>
    )
  }

  const Spacer = (height = '2em') => {
    const style = {
      borderBottom: 'none',
      height: height,
      margin: '1rem auto'
    }
    return (
      <hr style={style} />
    )
  }


  return (
    <div ref={formRef} style={formStyle}>

      <span
        id="balloonsReward"
        style={{
          position: 'fixed',
          bottom: '80px',
          left: '50%',
          zIndex: 10,
        }}
      />

      <form
        key={'add-form'}
        className={`form dialog-form`}
        onSubmit={(e) => onSubmit(e, formData)}
        style={{ paddingBottom: '6rem' }}>

          <FormGroup
            key='add-form-group'
            variant="outlined"
            style={fieldGroupStyle}>
            <Box style={{ paddingBottom : '2rem'}} >
              {showSearch
                ? <SearchBar title={title} message={message} />
                : null
              }
            </Box>

            {EventPicker}

            {FormInputText({ name: 'name', label: 'Name of Event', value: name, required: true, error: missing.includes('name') })}
            {FormInputText({ name: 'link', label: 'Link or website', value: link })}

            <TextField
              id="outlined-multiline-static"
              label="Event description"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              multiline
              rows={2}
              sx={descriptionStyle}
              value={description}
              onChange={(e, value) => handleFormUpdate(e, e?.target?.value, 'description')}
              onPaste={(e) => handleDescriptionPasted(e, e?.target?.value, 'description')}
            />

            <TagPicker onChange={handleTags} defaultValues={tagsCurrent} />

            <Spacer />

            <h4>Where & When</h4>

            <p style={{ marginBottom: '1rem' }}><strong>Location* </strong></p>
            {missing.includes('location')
              ? <p style={{
                color: 'red',
                fontSize: '1.2rem',
                marginBottom: '0.4rem',
                marginTop: '-1rem'
              }}>A Location is required; city-wide or virtual event, type the city.</p>
              : null
            }
            <div className='add-place'>
              <VibemapSearchProvider
                doAutoSearch
                //storedCities={data.allWpCity.nodes}
                preferStoredCities
                showGuides={false}
                shouldUpdateURL={false}>
                <div className='container'>
                  <fieldset>
                    <VibemapSearchParameterPersister />
                    <VibemapSearchField
                      autoComplete
                      autoFocus={false}
                      doSearchEvents={false}
                      doSearchGuides={false}
                      onClick={handlePlaceClick}
                      onUpdate={handlePlaceUpdate}
                      initialSearch={venue}
                      placeholder={`Type address or name of event venue`}
                      shouldClearForm={clearForm}
                      showCities={false}
                      showGuides={false}
                      searchGeocoder />
                  </fieldset>
                </div>
              </VibemapSearchProvider>
            </div>
            {place && address
              ? FormInputText({
                name: 'address',
                label: 'Address',
                placeholder: 'Search place or enter address',
                value: address
              })
              : null
            }

            <Grid sx={gridStyle}>
              <p><strong>Date & Time</strong></p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* IMPORTANT: Must use fuction style; not JSX */}
                {DatePicker({
                  label: 'Start Date',
                  value: startDate,
                  setValue: setStartDate,
                  isMobile: isMobile,
                  sx: { paddingRight: '0.2rem' }
                })}
                {TimePicker({
                  label: 'Start Time',
                  value: startDate,
                  setValue: setStartTime,
                  isMobile: isMobile,
                })}
              </LocalizationProvider>
            </Grid>
            <Grid sx={gridStyle}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  setValue={(value) => debounceTime(setEndDate, value)}
                  sx={{ paddingRight: '0.2rem' }}
                  />
                <TimePicker label="End Time" value={endDate} setValue={(value) => debounceTime(setEndTime, value)}  />
              </LocalizationProvider>
            </Grid>

            {CheckboxInput({
              name: 'recurs',
              label: 'Recurs or has more dates?'

            })}

            {formData?.recurs
              ? <RecurringDates
                  defaultRule={defaultRecurrence}
                  onChange={handleRRuleChange} />
              : null
            }

            {FormInputText({ name: 'price_display', label: 'Price', value: price })}
            {CheckboxInput({ name: 'is_free', label: 'Free event?' })}
            {CheckboxInput({ name: 'is_online', label: 'Is online?' })}

            {FileUploadComponent()}

          </FormGroup>

          <FormGroup>
            <h4>Event Organizer</h4>
           {FormInputText({ name: 'organizer_name', label: 'Host / Organizer' })}
           {FormInputText({ name: 'organizer_email', label: 'Email / Contact' })}
          </FormGroup>

          {showButtons ?
            <div className='buttonGroup' style={buttonGroupStyle}>
              {showPoints
                ? <VibePoints vibeScore={vibeScore} />
                : null
              }

              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                disabled={isLoading || !isValid}
                style={{ marginRight: 8 }}
                type="submit"
                color="primary"
                variant="contained"
                onClick={(e) => onSubmit(e, formData)}>
                Submit
              </Button>
            </div>
            : null
          }

      </form>
      {Buttons}
    </div>
  )
}

//AddEventForm.whyDidYouRender = true
export default pure(AddEventForm);
